<template>
  <b-card
    class="col-10 offset-md-1"
    :title="'Questionnaire - ' + project.name">

    <div :class="'social-button ' + (isGeneral ? 'active' : '')">
      General
      <b-icon icon="pencil" @click="editGeneral(key)"></b-icon>
    </div>

    <div :class="'social-button ' + (currentSection == key ? 'active' : '')" v-for="(item, key) in sections" v-bind:key="key">
      {{ item.name }}
      <b-icon icon="pencil" @click="editSection(key)"></b-icon>
      <b-icon icon="x" @click="deleteSection(key)"></b-icon>
    </div>
    <input type="text" @keyup="addSection" placeholder="Add section" v-model="newSection" />

    <hr>

    <div v-if="isGeneral">
      <h4>General section list</h4>
      <div class="row" v-for="(item, key) in general" v-bind:key="key" style="border-bottom: 1px solid #ccc; justify-content: center !important; margin-bottom: 10px;">
        {{item.text}}
        <b-icon icon="x" @click="deleteGeneralQuestion(key)"></b-icon>
      </div>
      <input type="text" @keyup="addGeneralQuestion" placeholder="Add general question" v-model="newGeneral" />
    </div>

    <div v-if="currentSection !== undefined">
      <h6>Social Topics</h6>
      <div  :class="'social-button ' + (currentSocialTopic == key ? 'active' : '')" v-for="(item, key) in sections[currentSection].socialTopics" v-bind:key="key">
        {{ item.name }}
        <b-icon icon="pencil" @click="editSocialTopic(key)"></b-icon>
        <b-icon icon="x" @click="deleteSocialTopic(key)"></b-icon>
      </div>
      <input type="text" @keyup="addSocialTopic" placeholder="Add social topic" v-model="newSocialTopic" />

      <div v-if="currentSocialTopic !== undefined">
        <hr>
        <h6>Questions</h6>
        <table class="table striped">
          <thead>
            <tr>
              <th>Question</th>
              <th>Info</th>
              <th>-2</th>
              <th>-1</th>
              <th>0</th>
              <th>1</th>
              <th>2</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(item, key) in sections[currentSection].socialTopics[currentSocialTopic].questions"
              v-bind:key="key"
              >
              <td><textarea v-model="sections[currentSection].socialTopics[currentSocialTopic].questions[key].question"></textarea></td>
              <td><textarea v-model="sections[currentSection].socialTopics[currentSocialTopic].questions[key].info"></textarea></td>
              <td><textarea v-model="sections[currentSection].socialTopics[currentSocialTopic].questions[key].a"></textarea></td>
              <td><textarea v-model="sections[currentSection].socialTopics[currentSocialTopic].questions[key].b"></textarea></td>
              <td><textarea v-model="sections[currentSection].socialTopics[currentSocialTopic].questions[key].c"></textarea></td>
              <td><textarea v-model="sections[currentSection].socialTopics[currentSocialTopic].questions[key].d"></textarea></td>
              <td><textarea v-model="sections[currentSection].socialTopics[currentSocialTopic].questions[key].e"></textarea></td>
              <td>
                <b-icon icon="x" @click="deleteQuestion(key)"></b-icon>
              </td>
            </tr>
          </tbody>
        </table>

        <button class="btn btn-success" @click="addQuestion">Add question</button>
      </div>
    </div>

    <b-button variant="success" class="my-4" @click="saveQuestionnaire">Save</b-button>
    <b-button variant="primary" class="my-4" @click="exportQuestionnaire" v-if="questId">Export</b-button>
  </b-card>
</template>

<script>
export default {
  name: 'Questionnaire',

  data: function () {
    return {
      project: {
        name: ''
      },
      isGeneral: false,
      sections: [],
      general: [],
      newSection: '',
      newSocialTopic: '',
      newGeneral: '',
      currentSection: undefined,
      currentSocialTopic: undefined,
      questId: undefined
    }
  },

  mounted () {
    this.fetchProject()
  },

  methods: {
    async fetchProject () {
      let project = await this.$firestore.collection('projects')
        .doc(this.$route.params.id)
        .get()

      this.project = {
        ...project.data(),
        id: project.id
      }

      let questionnaire = await this.$firestore.collection('questionnaires')
        .where('project', '==', this.$route.params.id)
        .get()

      if (questionnaire.size) {

        questionnaire.forEach(doc => {
          this.questId = doc.id
          this.sections = doc.data().sections
          this.general = doc.data().general
        });

        if (!this.general) {
          this.general = []
        }
      }
    },

    addSection (evt) {
      if (evt.code == 'Enter') {
        this.sections.push({
          name: this.newSection,
          socialTopics: []
        })
        this.newSection = ''
      }
    },

    addSocialTopic (evt) {
      if (evt.code == 'Enter') {
        this.sections[this.currentSection].socialTopics.push({
          name: this.newSocialTopic,
          questions: []
        })
        this.newSocialTopic = ''
      }
    },

    editSection (id) {
      this.isGeneral = false
      this.currentSection = id
    },

    editGeneral () {
      this.currentSection = undefined
      this.currentSocialTopic = undefined
      this.isGeneral = true
    },

    editSocialTopic (id) {
      this.currentSocialTopic = id
    },

    deleteSection (id) {
      if (id == this.currentSection) {
        this.currentSection = undefined
      }

      this.sections.splice(id, 1)
    },

    deleteSocialTopic (id) {
      if (id == this.currentSocialTopic) {
        this.currentSocialTopic = undefined
      }

      this.sections[this.currentSection].socialTopics.splice(id, 1)
    },

    deleteQuestion (id) {
      this.sections[this.currentSection].socialTopics[this.currentSocialTopic].questions.splice(id, 1)
    },

    deleteGeneralQuestion (id) {
      this.general.splice(id, 1)
    },

    addQuestion () {
      let baseQuestion = {
        question: '',
        info: '',
        a: '',
        b: '',
        c: '',
        d: '',
        e: ''
      }

      this.sections[this.currentSection].socialTopics[this.currentSocialTopic].questions.push(baseQuestion)
    },

    addGeneralQuestion (evt) {
      if (evt.code == 'Enter') {
        this.general.push({
          text: this.newGeneral
        })
        this.newGeneral = ''
      }
    },

    async saveQuestionnaire () {
      try {

        let responses = await this.$firestore.collection('questionnaires')
          .where('project', '==', this.$route.params.id)
          .get()

        if (responses.size) {
          responses.forEach(async (doc) => {
            await this.$firestore.collection('questionnaires').doc(doc.id).delete()
          });
        }

        await this.$firestore.collection('questionnaires').add({
          sections: this.sections,
          general: this.general,
          project: this.$route.params.id
        })

        this.$swal('Questionnaire saved')
      } catch (e) {
          console.log(e)
        this.$swal('Saving failed')
      }
    },

    async exportQuestionnaire () {
      location.href = 'https://us-central1-lce-slca.cloudfunctions.net/api/questionnaire/' + this.questId
    }
  }
}
</script>

<style scoped>
.social-button {
  display: inline-block;
  height: 35px;
  line-height: 35px;
  padding: 0 5px;
  border: 1px solid #dadada;
  margin-right: 10px;
  vertical-align: middle;
}

.social-button.active {
  border: 1px solid green;
  background-color: rgba(0, 255, 0, 0.2);
}

textarea {
  width: 80px;
  height: 100px;
  font-size: 12px;
}
</style>
